<template>
  <div>
    <div class="main-content-wrap d-flex flex-column">
      <div id="top">
        <Header title="Accounts" sub-title="Client"/>
        <Introduction />
        <b-container id="start" class="main-content questionnaire">
          <b-row>
            <b-col class="fieldset-wrapper" lg="7">
              <b-row>
                <b-col>
                  <SignUpForm/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpForm from "../../../components/common/questionnaires/fieldsets/register/SignUpForm";
import Header from "../../../components/common/questionnaires/questionnaire/Header";
import Introduction from "../../../components/common/questionnaires/questionnaire/Introduction";

export default {
  name: 'SignUp',
  components: {Introduction, Header, SignUpForm},
  mounted () {
    // to sign up a user must already have a client account created and have been issued an access code
    if (this.$route.params.code) {
      // clear tokens if logged in
      this.$store.commit('removeTokens');
      // client one time access via code
      this.$store.dispatch('setClientAuthorizationHeader', this.$route.params.code)
      this.$store.commit('accessCode', this.$route.params.code)
    }
  }
}
</script>
